/* eslint-disable */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// React & Gatsby
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Jumbotron from 'react-bootstrap/Jumbotron'
// import Button from 'react-bootstrap/Button';
// import Image from 'react-bootstrap/Image';

// Custom
import Hero from "../components/Hero"
import Page from "../components/Layout/Page"
import Products from '../components/Content/Products';
// import Footer from "../components/Footer"
// import Schema from "../components/Schema"
// import Meta from "../components/Meta"
// import Feature from '../components/Content/Feature';
// import Story from '../components/Content/Story';
// import menuItems from "../data/menu-items.json"
// import MainMenu from "../components/MainMenu"

// Data & Styles
// import footerLinks from "../data/footer-links.json"
import "../assets/scss/App.scss"


class ProductsPage extends Component {
    render() {
        return (
            <Page hero={<Hero/>}>
                <Container className={'mt-4 mb-4'}>
                    <Products/>
                </Container>
            </Page>
        )
    }
}

export default ProductsPage
